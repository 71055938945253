import { useState, useReducer, useEffect, useContext } from "react";
import { Box, Stack, Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation, useNavigate } from "react-router-dom";
import { primaryColor } from "../utils/color";
import { ProfileContext } from "../context/ProfileContextProvider";
import { FullScreen } from "../components/FullScreen";
import { PropertyPostCongratulation } from "../components/PropertyPostCongratulation";
import { PreListPropertyScreen } from "../components/PreListPropertyScreen";
import { useSwipeable } from "react-swipeable";
import { PropertyPostFalilure } from "../components/PropertyPostFailure";
import "react-datepicker/dist/react-datepicker.css";
import { InfoTabListProperty } from "../components/info-tab-list-property";
import { DetailsTabListProperty } from "../components/details-tab-list-property";
import { MediaTabListProperty } from "../components/media-tab-list-property";
import { ScreenBottomButton } from "../components/screen-bottom-button";
import { checkValidity, deletePendingPost, hasChanges } from "../helper/helper";
import { UseSavePost } from "../hooks/use-save-post";
import { SomethingWentWrongModal } from "../modals/someting-went-wrong";
import { TextLoader } from "../components/text-loader";
import { PostAmountModal } from "../modals/post-amount";
import { UsePayPost } from "../hooks/use-pay-post";
import { loadScript } from "../utils/loadScript";
import {
  initailMedia,
  initialPostPropertyInfo,
  mediaReducer,
  postPropertyInfoReducer,
} from "../reducer/reducer";
import { ListPropertyHeader } from "../components/list-property-header";
import { RestrictPageReload } from "../components/restrict-page-reload";
import { isDevMode } from "../utils/is-dev-mode";
import { MediaLoader } from "../components/media-loader";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestoreDb } from "../firebase/firebaseConfig";
import { TitleTextActionModal } from "../modals/title-text-action";
import { TitleTextActionActionModal } from "../modals/title-text-action-action";
import { UsePendingPost } from "../hooks/use-pending-post";
import { PendingDiscardModal } from "../modals/pending-discard";
import { BackwardDiscardModal } from "../modals/backward-discard";
import { UseFreePost } from "../hooks/use-free-post";

export const ListProperty = () => {
  const location = useLocation();
  const { navigatedData, navigatedFlag } = location.state || {};
  const isEditPost = navigatedFlag?.isEditPost;
  const editPostUid = navigatedData?.editPostUid;

  const { user } = useContext(ProfileContext);
  const { userInfo, userData } = user;

  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("1");
  const [isPostDetailsChanges, setIsPostDetailsChanges] = useState(false);
  const [isSaveToContinueDisabled, setIsSaveToContinueDisabled] =
    useState(true);
  const [isPreListPropertyScreen, setIsPreListPropertyScreen] = useState(true);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState({
    isOpen: false,
  });

  const [postPropertyInfo, postPropertyInfoDispatch] = useReducer(
    postPropertyInfoReducer,
    initialPostPropertyInfo
  );
  const [media, mediaDispatch] = useReducer(mediaReducer, initailMedia);

  const { pendingPostInfo, setPendingPostInfo } = UsePendingPost(
    userInfo.uid,
    isEditPost,
    editPostUid,
    setIsPreListPropertyScreen,
    postPropertyInfoDispatch,
    mediaDispatch
  );
  const { savingPostStatus, postRef, setSavingPostStatus, handleSavePost } =
    UseSavePost(
      postPropertyInfo,
      userInfo.uid,
      isEditPost,
      media.uploadedMedia,
      userData?.contactNumber,
      pendingPostInfo,
      setPendingPostInfo
    );
  const {
    payPostStatus,
    setPayPostStatus,
    razorpayIds,
    isWhiteScreen,
    handlePay,
  } = UsePayPost(user, postPropertyInfo?.montlyRent);
  const { freePostStatus, setFreePostStatus, handleFreePost } = UseFreePost();

  const handlers = useSwipeable({
    delta: 20,
    onSwipedLeft: () =>
      parseInt(tabValue, 10) < 3 &&
      setTabValue(String(parseInt(tabValue, 10) + 1)),
    onSwipedRight: () =>
      parseInt(tabValue, 10) > 1 &&
      setTabValue(String(parseInt(tabValue, 10) - 1)),
  });

  useEffect(() => {
    if (!userData?.contactNumber) {
      navigate("/verification", {
        replace: true,
      });
    }
  }, [userData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isPreListPropertyScreen]);

  useEffect(() => {
    setIsPostDetailsChanges(
      hasChanges(postPropertyInfo, initialPostPropertyInfo, media, initailMedia)
    );
    setIsSaveToContinueDisabled(!checkValidity(postPropertyInfo, media));
  }, [postPropertyInfo, media]);

  if (pendingPostInfo?.isPendingPostFetching) {
    return <TextLoader text="" />;
  }

  if (savingPostStatus?.isUploadingImages) {
    return (
      <MediaLoader uploadingMediaInfo={savingPostStatus?.uploadingMediaInfo} />
    );
  }

  if (savingPostStatus?.isSavingPost) {
    return <TextLoader text="Saving Post..." />;
  }

  if (payPostStatus?.isPaymentModalLoading) {
    return <TextLoader text="Loading Payment..." />;
  }

  if (payPostStatus?.isValidatingPaymentSignature) {
    return <TextLoader text="Processing Payment..." />;
  }

  if (freePostStatus?.isLoading) {
    return <TextLoader text="Processing Post..." />;
  }

  if (isPreListPropertyScreen) {
    return (
      <PreListPropertyScreen
        setIsPreListPropertyScreen={setIsPreListPropertyScreen}
      />
    );
  }

  if (savingPostStatus?.status === true) {
    return (
      <PostAmountModal
        value="status"
        state={savingPostStatus}
        setState={setSavingPostStatus}
        montlyRent={postPropertyInfo?.montlyRent}
        handlePay={(couponCode) => handlePay(postRef.id, couponCode)}
        handleFreePost={() => handleFreePost(postRef.id)}
      />
    );
  }

  if (payPostStatus?.isFailed) {
    return <PropertyPostFalilure razorpayIds={razorpayIds} />;
  }

  if (payPostStatus?.isSuccess || freePostStatus?.isSuccess) {
    return (
      <PropertyPostCongratulation
        razorpayIds={razorpayIds}
        isFreePost={freePostStatus?.isFreePost}
      />
    );
  }

  if (isWhiteScreen) {
    return <FullScreen bgcolor="white"></FullScreen>;
  }

  return (
    <>
      <SomethingWentWrongModal
        value="isUploadingImagesError"
        state={savingPostStatus}
        setState={setSavingPostStatus}
      />
      <SomethingWentWrongModal
        value="isSavingPostError"
        state={savingPostStatus}
        setState={setSavingPostStatus}
      />
      <SomethingWentWrongModal
        value="isPaymentError"
        state={payPostStatus}
        setState={setPayPostStatus}
      />
      <SomethingWentWrongModal
        value="isError"
        state={freePostStatus}
        setState={setFreePostStatus}
      />
      <PendingDiscardModal
        userUid={userInfo.uid}
        pendingPostInfo={pendingPostInfo}
        setPendingPostInfo={setPendingPostInfo}
        postPropertyInfoDispatch={postPropertyInfoDispatch}
        mediaDispatch={mediaDispatch}
      />
      <BackwardDiscardModal
        isDiscardModalOpen={isDiscardModalOpen}
        setIsDiscardModalOpen={setIsDiscardModalOpen}
        isPostDetailsChanges={isPostDetailsChanges}
        pendingPostInfo={pendingPostInfo}
        userUid={userInfo.uid}
        isEditPost={isEditPost}
        handleSavePost={() => handleSavePost("SAVE_PENDING_POST")}
      />
      <RestrictPageReload restrictConditionArr={[!isPreListPropertyScreen]} />
      <Box>
        <ListPropertyHeader isPostDetailsChanges={isPostDetailsChanges} />
        <Box sx={{ width: "100%", height: "100vh" }} {...handlers}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                variant="fullWidth"
              >
                <Tab label="Info" value="1" />
                <Tab label="Details" value="2" />
                <Tab label="Media" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <InfoTabListProperty
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                isEditPost={isEditPost}
              />
            </TabPanel>
            <TabPanel value="2">
              <DetailsTabListProperty
                postPropertyInfo={postPropertyInfo}
                postPropertyInfoDispatch={postPropertyInfoDispatch}
                isEditPost={isEditPost}
              />
            </TabPanel>
            <TabPanel value="3">
              <MediaTabListProperty
                media={media}
                mediaDispatch={mediaDispatch}
                pendingPostInfo={pendingPostInfo}
              />
              <ScreenBottomButton
                text={isEditPost ? "Save" : "Save To Continue"}
                action={() => handleSavePost("SAVE_CONTINUE_POST")}
                isButtonDisabled={
                  isDevMode() || isEditPost ? false : isSaveToContinueDisabled
                }
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export const FieldHeading = ({ heading }) => {
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1}>
      <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
        {heading}
      </Typography>
      <Typography
        color={primaryColor[900]}
        sx={{ fontSize: "16px", fontWeight: "500" }}
      >
        *
      </Typography>
    </Stack>
  );
};
