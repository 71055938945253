import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb, messaging } from "../firebase/firebaseConfig";
import { getToken } from "firebase/messaging";
import { saveMessagingDeviceToken } from "../helper/helper";
import { isAndroid } from "react-device-detect";
import { isApp } from "../utils/isApp";

export const UseNotificationInfo = (userUid, hasVisitedToday, fcmToken) => {
  const [notificationInfo, setNotificationInfo] = useState({
    status: Notification.permission,
    isNotificationEnableModalOpen: false,
    isNotificationEnableInstructionsModalOpen: false,
  });

  const requestNotificationsPermission = async () => {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("permission granted - Myposts.js - mylogs :", permission);
      saveMessagingDeviceToken(userUid);
      setNotificationInfo({
        ...notificationInfo,
        status: "granted",
        isNotificationEnableModalOpen: false,
      });
    } else {
      console.log("permission denied - Myposts.js - mylogs :", permission);
      setNotificationInfo({
        ...notificationInfo,
        isNotificationEnableInstructionsModalOpen: true,
        isNotificationEnableModalOpen: false,
      });
    }
  };

  useEffect(() => {
    if (
      isAndroid &&
      isApp() &&
      (!hasVisitedToday || !Boolean(fcmToken)) &&
      notificationInfo.status === "granted"
    ) {
      saveMessagingDeviceToken(userUid);
    }
  }, []);

  return {
    notificationInfo,
    setNotificationInfo,
    requestNotificationsPermission,
  };
};
