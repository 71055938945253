import { green, grey, red } from "@mui/material/colors";
import { Timestamp } from "firebase/firestore";
import { POST_STATUS } from "../constants/constants";

export const convertFirebaseTimestamp = (time) => {
  if (!time) return null;

  return time instanceof Date
    ? time
    : new Timestamp(time?.seconds, time?.nanoseconds).toDate();
};

export const splitStringBasedOnFirstSpace = (input) => {
  const firstSpaceIndex = input.indexOf(" ");

  if (firstSpaceIndex === -1) {
    return [input, ""];
  }

  const firstPart = input.substring(0, firstSpaceIndex);
  const secondPart = input.substring(firstSpaceIndex + 1);

  return [firstPart, secondPart];
};

export const getPostTags = (
  isMyPostsPage,
  paymentStatus,
  postStatus,
  postType
) => {
  const postTags = [
    postType === "direct-calling" && {
      variantColor: green[800],
      tag: "Direct Calling",
    },
    isMyPostsPage &&
      paymentStatus === "failed" && {
        variantColor: red[800],
        tag: "Payment Failed",
      },
    isMyPostsPage &&
      paymentStatus === "invalid" && {
        variantColor: red[800],
        tag: "Invalid Payment",
      },
    isMyPostsPage &&
      paymentStatus === "success" &&
      postStatus === POST_STATUS.IN_REVIEW && {
        variantColor: green[800],
        tag: "In-Review",
      },
    isMyPostsPage &&
      paymentStatus === "success" &&
      postStatus === POST_STATUS.ACTION_REQUIRED && {
        variantColor: "#c27610",
        tag: "Action Required",
      },
    isMyPostsPage &&
      paymentStatus === "success" &&
      postStatus === POST_STATUS.REMOVED && {
        variantColor: red[800],
        tag: "Removed",
      },
    isMyPostsPage &&
      paymentStatus === "success" &&
      postStatus === POST_STATUS.EXPIRED && {
        variantColor: grey[800],
        tag: "Expired",
      },
  ];

  return postTags.filter((postTag) => postTag !== false);
};

export const hasVisitedToday = () => {
  const lastVisit = new Date(localStorage.getItem("lastVisitAt"));
  const today = new Date();

  const hasVisited = lastVisit.toDateString() === today.toDateString();
  localStorage.setItem("lastVisitAt", today.toISOString());

  return hasVisited;
};
