import { Box, Button, Stack } from "@mui/material";
import { Spacer } from "./Spacer";
import { primaryBgColor } from "../utils/color";
import { Link, useNavigate } from "react-router-dom";
import {
  POST_STATUS,
  REQUEST_LIVE_STATUS,
  requestStatus,
} from "../constants/constants";
import { UseDcCallNow } from "../hooks/use-dc-call-now";
import { SomethingWentWrongModal } from "../modals/someting-went-wrong";
import { PreRequestFormModal } from "../modals/pre-request-form";
import { Delete, Phone, SensorOccupied } from "@mui/icons-material";

export const ViewPropertyFooter = ({
  postData,
  requestRes,
  isOwnerViewPost,
  isOwnerNavigatedFromMyPosts,
  isCallNowModalOpen,
  setIsCallNowModalOpen,
  setIsRequestModalOpen,
  isUserSignedIn,
  userContactNumber,
  handleDeleteRequest,
}) => {
  const navigate = useNavigate();
  const { dcCallNowInfo, setDcCallNowInfo, handleDcCallNow } = UseDcCallNow(
    postData,
    isUserSignedIn,
    userContactNumber
  );

  const handleContactOwner = () => {
    if (!isUserSignedIn || !userContactNumber) {
      navigate("/login");
      return;
    }
    setIsCallNowModalOpen(true);
  };

  if (postData?.paymentStatus !== "success") {
    return (
      <>
        <Spacer space={50} />
      </>
    );
  }

  if (postData?.postType === "direct-calling") {
    return (
      <>
        <PreRequestFormModal
          isCallNowModalOpen={isCallNowModalOpen}
          setIsCallNowModalOpen={setIsCallNowModalOpen}
          setIsRequestModalOpen={setIsRequestModalOpen}
          postChargePercentage={postData?.postChargePercentage}
          handleDcCallNow={handleDcCallNow}
          postType={postData?.postType}
          dcCallNowInfo={dcCallNowInfo}
          isRequestApproved={
            requestRes?.requestData?.requestStatus === requestStatus.approved
          }
          ownerContactNumber={requestRes?.requestData?.ownerContactNumber}
        />
        <SomethingWentWrongModal
          value="isError"
          state={dcCallNowInfo}
          setState={setDcCallNowInfo}
        />
        <Box>
          <Spacer space={50} />
          <Stack
            alignItems="center"
            sx={{
              position: "fixed",
              bottom: "0px",
              right: "0px",
              left: "0px",
              zIndex: "99",
              paddingBottom: "30px",
              paddingTop: "15px",
              bgcolor: `${primaryBgColor}`,
            }}
          >
            <Button
              variant="contained"
              sx={{ width: "90%", boxShadow: "6" }}
              startIcon={<Phone />}
              onClick={handleContactOwner}
            >
              Call Owner
            </Button>
          </Stack>
          <Spacer space={50} />
        </Box>
      </>
    );
  }

  if (isOwnerViewPost) {
    if (postData?.paymentStatus === "success" && postData?.postStatus === POST_STATUS.ACTIVE) {
      return (
        <Box>
          <Spacer space={50} />
          <Stack
            alignItems="center"
            sx={{
              position: "fixed",
              bottom: "0px",
              right: "0px",
              left: "0px",
              zIndex: "99",
              paddingBottom: "30px",
              paddingTop: "15px",
              bgcolor: `${primaryBgColor}`,
            }}
          >
            <Button
              component={Link}
              to={"/post-requests"}
              state={{
                navigatedData: {
                  postUid: postData?.postUid,
                },
              }}
              sx={{ width: "90%", height: "32px" }}
              variant="contained"
            >
              View Requests
            </Button>
          </Stack>
          <Spacer space={50} />
        </Box>
      );
    } else {
      return (
        <>
          <Spacer space={25} />
        </>
      );
    }
  }

  if (requestRes?.isLoading) {
    return <></>;
  }

  if (
    postData?.postStatus === POST_STATUS.EXPIRED ||
    postData?.postStatus === POST_STATUS.REMOVED
  ) {
    return (
      <>
        {requestRes?.requestData?.liveStatus === REQUEST_LIVE_STATUS.ACTIVE ? (
          <Box>
            <Spacer space={50} />
            <Stack
              alignItems="center"
              sx={{
                position: "fixed",
                bottom: "0px",
                right: "0px",
                left: "0px",
                zIndex: "99",
                paddingBottom: "30px",
                paddingTop: "15px",
                bgcolor: `${primaryBgColor}`,
              }}
            >
              <Button
                variant="contained"
                sx={{ width: "90%", boxShadow: "6" }}
                startIcon={<Delete />}
                onClick={() =>
                  handleDeleteRequest(requestRes?.requestData?.requestUid)
                }
              >
                Delete Request
              </Button>
            </Stack>
            <Spacer space={50} />
          </Box>
        ) : (
          <>
            <Spacer space={50} />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <PreRequestFormModal
        isCallNowModalOpen={isCallNowModalOpen}
        setIsCallNowModalOpen={setIsCallNowModalOpen}
        setIsRequestModalOpen={setIsRequestModalOpen}
        postChargePercentage={postData?.postChargePercentage}
        handleDcCallNow={handleDcCallNow}
        postType={postData?.postType}
        dcCallNowInfo={dcCallNowInfo}
        isRequestApproved={
          requestRes?.requestData?.requestStatus === requestStatus.approved
        }
        ownerContactNumber={requestRes?.requestData?.ownerContactNumber}
      />
      <Box>
        {requestRes?.requestData ? (
          <>
            {requestRes?.requestData?.requestStatus ===
              requestStatus.approved && (
              <Box>
                <Spacer space={50} />
                <Stack
                  alignItems="center"
                  sx={{
                    position: "fixed",
                    bottom: "0px",
                    right: "0px",
                    left: "0px",
                    zIndex: "99",
                    paddingBottom: "30px",
                    paddingTop: "15px",
                    bgcolor: `${primaryBgColor}`,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: "90%", boxShadow: "6" }}
                    startIcon={<Phone />}
                    onClick={handleContactOwner}
                  >
                    Call Owner
                  </Button>
                </Stack>
                <Spacer space={50} />
              </Box>
            )}
          </>
        ) : (
          <Box>
            <Spacer space={50} />
            <Stack
              alignItems="center"
              sx={{
                position: "fixed",
                bottom: "0px",
                right: "0px",
                left: "0px",
                zIndex: "99",
                paddingBottom: "30px",
                paddingTop: "15px",
                bgcolor: `${primaryBgColor}`,
              }}
            >
              <Button
                variant="contained"
                sx={{ width: "90%", boxShadow: "6" }}
                startIcon={<SensorOccupied />}
                onClick={handleContactOwner}
              >
                Contact Owner
              </Button>
            </Stack>
            <Spacer space={50} />
          </Box>
        )}
        {requestRes?.requestData?.requestStatus && <Spacer space={50} />}
      </Box>
    </>
  );
};
