import { Box, Stack } from "@mui/material";
import { Spacer } from "../components/Spacer";
import { primaryBgColor } from "../utils/color";
import { useContext, useEffect } from "react";
import { MainPropertyCard } from "../components/MainPropertyCard";
import { SomethingWentWrongScreen } from "../components/something-went-wrong-screen";
import { MyPostsContext } from "../context/MyPostsContextProvider";
import { MyPostsHeader } from "../components/my-posts-header";
import { TextLoader } from "../components/text-loader";
import { ListPropertyScreen } from "../components/list-property-screen";
import { useNavigate } from "react-router-dom";

export const MyPosts = () => {
  const navigate = useNavigate();

  const { myPostsRes, setTriggerMyPostsRes } = useContext(MyPostsContext);

  // To trigger myPostsRes when the user navigates to this page
  useEffect(() => {
    setTriggerMyPostsRes(true);
  }, []);

  useEffect(() => {
    const handlePopstate = (event) => {
      navigate("/", { replace: true });
    };

    window.history.pushState(
      window?.history?.state,
      null,
      window.location.pathname
    );
    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  if (myPostsRes?.isLoading) {
    return <TextLoader text="Loading..." />;
  }

  if (myPostsRes?.isError) {
    return <SomethingWentWrongScreen />;
  }

  if (myPostsRes?.myPostsData?.length === 0) {
    return (
      <>
        <MyPostsHeader />
        <ListPropertyScreen />
      </>
    );
  }

  return (
    <>
      <Box>
        <MyPostsHeader />
        <Box sx={{ bgcolor: `${primaryBgColor}` }}>
          <Spacer space={20} />
          <Stack spacing={3} sx={{ paddingX: "12px" }}>
            {myPostsRes?.myPostsData?.map((postData, i) => (
              <MainPropertyCard
                key={i}
                postData={postData}
                isOwnerNavigatedFromMyPosts={true}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </>
  );
};
