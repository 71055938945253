import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestoreDb } from "../firebase/firebaseConfig";
import { POST_STATUS, REQUEST_LIVE_STATUS } from "../constants/constants";
import { useNavigate } from "react-router-dom";

export const UseRequestInfo = (
  postData,
  userUid,
  isUserSignedIn,
  isOwnerViewPost,
  requestInfo,
  setIsCallNowModalOpen,
  setIsRequestModalOpen
) => {
  const navigate = useNavigate();
  const [triggerRequestRes, setTriggerRequestRes] = useState(false);
  const [requestRes, setRequestRes] = useState({
    isError: false,
    isLoading: true,
    requestData: null,
    isHandlingRequestSend: false,
    handlingRequestSendSuccessStatus: false,
    handlingRequestSendFailedStatus: false,
    isDeletingRequest: false,
    deletingRequestSuccessStatus: false,
    deletingRequestFailedStatus: false,
  });
  const postUid = postData?.postUid;

  const handleRequestSend = async () => {
    setIsCallNowModalOpen(false);
    setIsRequestModalOpen(false);

    const basicPostInfo = {
      montlyRent: postData?.postInfo?.montlyRent,
      fullName: postData?.postInfo?.fullName,
      locality: postData?.postInfo?.address?.locality,
      bhk: postData?.postInfo?.bhk,
      mainMedia: postData?.media?.hall[0] || postData?.media?.bedrooms[0],
    };

    try {
      setRequestRes({
        ...requestRes,
        isHandlingRequestSend: true,
      });
      // throw new Error("Error sending request:");
      const newRequestRef = doc(collection(firestoreDb, "requests"));
      await setDoc(newRequestRef, {
        time: new Date(),
        requestUid: newRequestRef.id,
        userUid: userUid,
        ownerUid: postData?.ownerUid,
        postUid: postUid,
        postStatus: postData?.postStatus,
        liveStatus: REQUEST_LIVE_STATUS.ACTIVE,
        seen: false,
        requestInfo,
        basicPostInfo,
        requestStatus: "Pending",
      });
      console.log("successfully send request - mylogs");
      setTriggerRequestRes(!triggerRequestRes);
      setRequestRes({
        ...requestRes,
        isHandlingRequestSend: false,
        handlingRequestSendSuccessStatus: true,
      });
    } catch (err) {
      console.error(
        "err while sending request - ListProperty.js - mylogs :",
        err
      );
      setRequestRes({
        ...requestRes,
        isHandlingRequestSend: false,
        handlingRequestSendFailedStatus: true,
      });
    }
  };

  const handleDeleteRequest = async (requestUid) => {
    try {
      setRequestRes({
        ...requestRes,
        isDeletingRequest: true,
      });
      // throw new Error("Error deleting request:");
      await updateDoc(doc(firestoreDb, `requests/${requestUid}`), {
        liveStatus: REQUEST_LIVE_STATUS.REMOVED,
      });
      console.log("successfully delete request - mylogs");
      setTriggerRequestRes(!triggerRequestRes);
      setRequestRes({
        ...requestRes,
        isDeletingRequest: false,
        deletingRequestSuccessStatus: true,
      });
      window?.history?.state?.idx > 0 ? navigate(-1) : navigate("/");
    } catch (err) {
      console.error("err while deleting request - mylogs", err);
      setRequestRes({
        ...requestRes,
        isDeletingRequest: false,
        deletingRequestFailedStatus: true,
      });
    }
  };

  useEffect(() => {
    if (!isUserSignedIn || isOwnerViewPost || !postUid) {
      setRequestRes({
        ...requestRes,
        isLoading: false,
      });
      return;
    }

    if (postUid && userUid) {
      (async () => {
        try {
          // throw new Error("Error while fetching request response - ViewProperty.js - mylogs!");
          const q = query(
            collection(firestoreDb, "requests"),
            and(
              where("userUid", "==", userUid),
              where("postUid", "==", postUid),
              where("liveStatus", "==", REQUEST_LIVE_STATUS.ACTIVE)
            )
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length !== 0) {
            setRequestRes({
              ...requestRes,
              isLoading: false,
              requestData: querySnapshot.docs[0].data(),
            });
          } else {
            setRequestRes({
              ...requestRes,
              isLoading: false,
              requestData: null,
            });
          }
        } catch (err) {
          console.error(
            "err while fetching the request info - FilterActionSheet.js - mylogs :",
            err
          );
          setRequestRes({
            ...requestRes,
            isError: true,
            isLoading: false,
            requestData: null,
          });
        }
      })();
    }
  }, [postUid, userUid, triggerRequestRes]);

  return { requestRes, setRequestRes, handleRequestSend, handleDeleteRequest };
};
