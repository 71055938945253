import { Box, Typography } from "@mui/material";

export const AppVersion = () => {
  return (
    <>
      <Box>
        <Typography sx={{ fontSize: "10px", color: "white" }}>
          @ version - 1.7.5
        </Typography>
      </Box>
    </>
  );
};
