import { Navigate, Outlet } from "react-router-dom";
import { ProfileContext } from "../context/ProfileContextProvider";
import { useContext } from "react";

export const ProtectedRoutes = () => {
  const { user } = useContext(ProfileContext);
  const { isUserSignedIn } = user;

  return isUserSignedIn ? <Outlet /> : <Navigate to="/login" replace={true} />;
};
